.home {
    .row {
        display: flex;
        justify-content: space-evenly;
        margin-top: 200px;
        height: 100%;
        img {
            height: 150px;
            width: 150px;
            border-radius: 20px;
        }
    }
}