.we {
    .navbar {
        display: flex;
        gap: 10px;
        justify-content: center;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .title {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .short {
        font-size: 20px;
        margin-bottom: 20px;
        margin-left: 20%;
        margin-right:20%;
    }
    .description {
        display:flex;
        font-size: 18px;
        margin-left: 20%;
        margin-right:20%;
        text-align: start;
        white-space: pre-line;
    }
}