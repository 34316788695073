.support {
    h1 {
        font-size: 20px;
        margin-left: 20%;
        text-align: start;
    }
    h2 {
        font-size: 18px;
        margin-left: 20%;
        text-align: start;
    }
    h3 {
        margin-left: 20%;
        text-align: start;
        font-size: 16px;
        font-weight: normal;
    }
    p {
        font-size: 15px;
        font-weight: normal;
        margin-left: 20%;
        margin-right: 20%;
        color: grey;
        text-align: start;
        line-height: normal;
    }
    li {
        font-weight: normal;
        margin-left: 20%;
        margin-right: 20%;
        color: grey;
        text-align: start;
    }
}